import Context from "./Context";

let instance = null;
export default class Registry {
  constructor() {
    if (!instance) {
      this.components = {};
      this.contexts = {};
      instance = this;
    }
    return instance;
  }

  addContext(name, classObj) {
    this.contexts[name] = classObj;
    return this;
  }

  addComponent(name, classObj) {
    this.components[name] = classObj;
    return this;
  }

  getContext(name) {
    if (name && typeof this.contexts[name] === 'function') {
      return this.contexts[name];
    } else {
      return Context;
    }
  }

  getComponent(name) {
    if (name && typeof this.components[name] === 'function') {

      return this.components[name];
    } else {
      return false;
    }
  }
}
