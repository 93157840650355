import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton();
import $ from 'jquery';


  
  let sections = gsap.utils.toArray(".panel");
  const serviziScroll = document.querySelector("#serviziScroll");
  const bgrounded = document.getElementById('bg-rounded-mustard');
  
  if (bgrounded) {
    gsap.to(bgrounded, {
      scale: 1.0,
      scrollTrigger: {
        //markers: true,
        trigger: bgrounded,
        end: "top top",
        start: "top bottom",
        once: false, // Esegue l'animazione solo una volta
        onUpdate: self => {
          if( window.innerWidth > 768) {
            const progress = self.progress.toFixed(2); // Progresso dello scroll con due decimali
            const scaleFactor = window.innerWidth >= 1580 ? 1.0 + (progress * 10) : 1.0 + (progress * 8);
            gsap.set(bgrounded, {scale: scaleFactor,});
          };
          const bodyBackground = self.progress > 0.7 ? "#FBC94D" : "";
          const serviziScrollOpacity = self.progress > 0.99 ? "1" : "0";
          const contentOpacity = self.progress > 0.5 ? "1" : "0";
          
          document.body.style.background = bodyBackground;
          document.querySelector("#serviziScroll").style.opacity = serviziScrollOpacity;
          document.querySelector("#content").style.opacity = contentOpacity;
        }
  
      },
      ease: 'ease'
    });
  }
  
  if (serviziScroll) {
    if( window.innerWidth > 768) {
      gsap.to(sections, {
        xPercent: -100 * (sections.length - 2),
        ease: "none",
        scrollTrigger: {
          start: "top top",
          end: "+=500",
          trigger: "#serviziScroll",
          invalidateOnRefresh: true,
          pin: true,
          scrub: 1,
          snap: 1 / (sections.length - 3),
          end: () => "+=" + document.querySelector("#serviziScroll").offsetWidth,
          onUpdate: function (self) {
            if (self.progress > 0.8) {
              document.body.style.background = "#121212";
            } else {
              document.body.style.background = "#FBC94D";
            }
          },
        }
      });
    }
    else {
      gsap.to('body', {
        backgroundColor: '#FBC94D',
        scrollTrigger: {
          trigger: serviziScroll,
          start: 'center center',
          end: 'center center',
          scrub: true,
          onUpdate: (self) => {
            if (self.progress > 0.8) {
              document.body.style.background = "#121212";
              serviziScroll.style.background = "#121212";
              
            } else {
              document.body.style.background = "#FBC94D";
              serviziScroll.style.background = "#FBC94D";
            }
          },
        },
      });
    }
  }
  
  setTimeout(function(){
    ScrollTrigger.refresh();
  },150)