import $ from 'jquery';
import Component from "../models/Component";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class Filter extends Component {
  constructor(element, context) {
    super(element, context);
    $(() => {
      this.init();
      this.animateScrollTriggerParallax(); // Chiamare l'animazione iniziale
    });
  }

  init() {
    this.element.on('click', (e) => {
      $('.filter span').removeClass('active');
      this.element.addClass('active');
      let filterValue = this.element.data('filter');
      
      if (filterValue === 'all') {
        $('.casehistory').show();
      } else {
        $('.casehistory').hide();
        $('.casehistory[data-category*="' + filterValue + '"]').fadeIn();
      }
      
      this.animateScrollTriggerParallax(); // Chiamare l'animazione dopo il filtraggio
    });
  }
  
  animateScrollTriggerParallax() {
    let images = $('.casehistory img');

    images.each((index, image) => {
      gsap.fromTo(
        image,
        {
          scale: 0.7,
          opacity: 0,
        },
        {
          scale: 1.15,
          opacity: 1,
          duration: 1,
          scrollTrigger: {
            trigger: image,
            start: '20% bottom',
          },
        },
      );
  
      gsap.fromTo(
        image,
        {
          yPercent: -10,
        },
        {
          yPercent: 10,
          scrollTrigger: {
            trigger: image,
            start: 'top bottom',
            end: 'bottom top',
            scrub: true,
          },
        }
      );
    });
  }
}
