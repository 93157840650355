import SwiperSlider from "../components/abstract/SwiperSlider";
import $ from 'jquery';

export default class Ticker extends SwiperSlider {
  constructor(element, context) {
    super(element, context);

    this.options = {
      centeredSlides: true,
      speed: parseInt(this.getData('speed', 12000)),
      allowTouchMove: false,
      slidesPerView: 'auto',
      loop: true,
      autoplay: {
        delay: 1,
      },
    };

    $(() => {
      this.init();
    });
  }
}
