import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
import $ from 'jquery';

// const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton();


  // let section = gsap.utils.toArray(".gallery");
  
  // section.forEach(section => {
  //   let direction = section.getAttribute("data-direction");
  //   let xPercent = direction ? -10 : 10;
  //   const gallery = $(".gallery_wrapper");
  //   if (!direction) {
  //     /* section.firstElementChild.classList.add("translate-y-[17rem]"); */
  //     section.firstElementChild.classList.add("translate-slide-15");
      
  //   } else {
  //     /* section.firstElementChild.classList.add("translate-x-[-6%]"); */
  //     section.firstElementChild.classList.add("translate-slide-6");
  //   }
  
  //   const tween = gsap.to(section, {
  //     xPercent: xPercent,
  //     duration: 10,
  //     ease: 'ease'
  //   });
  
  //   const trigger = {
  //     trigger: section,
  //     start: "top bottom",
  //     scrub: true,
  //   };
  
  //   ScrollTrigger.create(trigger, tween);
  // });



const progettiSection = document.querySelector('.ch-color');
const trigger = {
  trigger: progettiSection,
  start: 'top top',
  onUpdate: self => {
    const progress = self.progress;
    // Utilizza il valore di progress per cambiare il comportamento dello sfondo
    if (progress > 0.2) {
      if($('body').hasClass('home')){
      document.body.style.background = "#fff";
      $('.casehistory h3').css('color', '#121212');
      }
    } else {
      if($('body').hasClass('home')){
        document.body.style.background = "#121212";
        $('.casehistory h3').css('color', '#fff');
      }

    }
  }
};

// Applica il trigger
ScrollTrigger.create(trigger); 