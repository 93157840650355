import $ from 'jquery';
import { $Page, $Doc } from '../utils/jQueryGlobals';
import Component from '../models/Component';

export default class Accordion extends Component {
  constructor(element, context) {
    super(element, context);
    if (this.element.data('offset')) {
      this.offsetElem = $(this.element.data('offset'));
    } else this.offsetElem = false;
    this.animation = false;
    this.speed = parseInt(this.getData('speed', 500));
    this.easing = this.getData('easing', 'swing');
    $(() => {
      this.init();
    });
  }

  openSection(section) {
    section.addClass('section-open');
    section.find('> .c').slideDown(this.speed, this.easing, () => {
      let top = section.offset().top;
      if (this.offsetElem) {
        top = top - this.offsetElem.outerHeight() - 20;
      }
      if (top < $Doc.scrollTop()) {
        $Page()
          .stop()
          .animate({ scrollTop: top }, this.speed, this.easing, () => {
            this.animation = false;
          });
      } else {
        this.animation = false;
      }
    });
  }

  closeSection(section) {
    section.removeClass('section-open');
    section.find('> .c').slideUp(this.speed, this.easing, () => {
      this.animation = false;
    });
  }

  init() {
    this.element.find('.h').each((index, e) => {
      let section = $(e).closest('.section');
      if (section.find('> .c').length) {
        $(e).on('click', () => {
          if (this.animation) {
            return false;
          }
          this.animation = true;

          if (section.hasClass('section-open')) {
            this.closeSection(section);
          } else {
            let active = this.element.find('.section-open');
            if (active.length) {
              this.closeSection(active);
            }
            this.openSection(section);
          }

          return false;
        });
      }
    });

    if (window.location.hash.length > 1) {
      const fragment = window.location.hash.substring(1);
      this.element.find(`.section[data-fragment=${fragment}] .h`).click();
    }
  }
}
