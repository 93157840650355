import $ from 'jquery';
import Component from '../models/Component';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class ScrollTriggerParallax extends Component {
  constructor(element, context) {
    super(element, context);

    $(() => {

        let image = this.element.find('img');

        gsap.fromTo(
            image,
            {
                scale: 0.7,
                opacity: 0,
            },
            {
                scale: 1.15,
                opacity: 1,
                duration: 1,
                scrollTrigger: {
                    trigger: image,
                    start: '10% bottom',
                },
            },
        );

        gsap.fromTo(
            image,
            {
                yPercent: -10,
            },
            {
                yPercent: 10,
                scrollTrigger: {
                    trigger: element,
                    start: 'top bottom',
                    end: 'bottom top',
                    scrub: true,
                },
            }
        )

    });
  }
}
