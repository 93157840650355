import Registry from "./models/Registry";
import MainMenu from "./components/MainMenu";
import Slider from "./components/Slider";
import './components/Video';
import './components/Fadein';
import './components/Cursor';
import './components/archive_casehistory';
import HeroHp from "./components/HeroHp";
import Filter from "./components/Filter";
import './components/Circle_link';
import './components/Parallax';
import  './components/Servizi';
import './components/Gallery';
import ImageCasehistory from "./components/ImageCasehistory";
import Accordion from "./components/Accordion"
import SliderTitolo from "./components/SliderTitolo";
import VimeoEmbed from "./components/VimeoEmbed";

class App {
  constructor(context) {
    this.registry = new Registry();
    this.registerComponents();
    this.initController(context);
    this.patchIos();
  }

  initController(contextData) {
    const Context = this.registry.getContext(contextData);
    new Context(contextData);
  }

  registerComponents() {
    this.registry
      .addComponent('MainMenu', MainMenu)
      .addComponent('Slider', Slider)
      .addComponent('Filter', Filter)
      .addComponent('HeroHp', HeroHp)
      .addComponent('ImageCasehistory', ImageCasehistory)
      .addComponent('Accordion', Accordion)
      .addComponent('SliderTitolo', SliderTitolo)
      .addComponent('VimeoEmbed', VimeoEmbed)
    ;
  }

  patchIos() {
    window.addEventListener('resize', () => this.appHeight())
    this.appHeight()
  }

  appHeight() {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }
}

window.AppInit = function(context) {
    new App(context);
};
