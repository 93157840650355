import $ from 'jquery';


$( document ).ready(function() {
  
  const $c = $("[data-custom-cursor]");
  const $progettiSection = $(".progetti-section");
  const $SliderSection = $(".slider");
  
  $progettiSection.hover(
    () => $c.addClass("progetti"),
    () => $c.removeClass("progetti")
  );
  
  $SliderSection.hover(
    () => $c.addClass("slider"),
    () => $c.removeClass("slider")
  );
  
  $(window).on("mousemove", ({ clientX: x, clientY: y }) => {
    $c.css({ transform: `translate(${x}px, ${y}px)` });
  });


});
