import $ from 'jquery';
import Component from '../models/Component';
import Player from "@vimeo/player";

export default class VimeoEmbed extends Component {
    constructor(element, context) {
        super(element, context);

        $(() => {
            // console.log(this.element.getId())
            this.player = new Player(this.element.get(0), {
                id: '840076111',
                autoplay: false,
                fullscreen: true,
                responsive: true,
                dnt: 1,
            })

            window.vPlayer = this.player
        })
    }
}
