import Component from "../../models/Component";
import Swiper, {Navigation, EffectFade, Pagination, Autoplay} from "swiper";

Swiper.use([Navigation, EffectFade, Pagination, Autoplay]);

export default class SwiperSlider extends Component {
  constructor(element, context) {
    super(element, context);
    this.swiper = {};
  }

  init() {
    this.swiper = new Swiper(this.element[0], this.options);
  }

  setOptions(options) {
    this.options = options;
  }

  updateSize() {
    if (typeof this.swiper.update === "function") {
      this.swiper.update();
    }
  }

  destroy() {
    if (typeof this.swiper.destroy === "function") {
      this.swiper.destroy();
    }
  }

  getSwiper() {
    return this.swiper;
  }

  setParam(param, value) {
    this.swiper.params[param] = value;
  }
}
