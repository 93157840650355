import $ from 'jquery';


$( document ).ready(function() {
  
  if($('body').hasClass('post-type-archive-case_history')) {
    let imageReveal = $('.image-reveal div')
    console.log(imageReveal);
   $(window).scroll(function() {
    var scrollPercentage = ($(window).scrollTop() / ($(document).height() - $(window).height())) * 100;
    if (scrollPercentage >= 50) {
      document.body.style.background = "#fff";
      $('.casehistory h3').css('color', '#121212');
      imageReveal.css("background", "#fff")
    } else {
      document.body.style.background = "#121212";
      $('.casehistory h3').css('color', '#fff');
      imageReveal.css("background", "#121212")
    }
   });
  }

});