import ScrollMagic from 'scrollmagic';
import {gsap} from 'gsap';
import {ScrollMagicPluginGsap} from 'scrollmagic-plugin-gsap';
import Component from "../models/Component";
import $ from 'jquery';
import Player from "@vimeo/player";

ScrollMagicPluginGsap(ScrollMagic, gsap);

export default class HeroHp extends Component {
  constructor(element, context) {
    super(element, context);
    $(() => {
      this.init();
    });
  }

  init() {
    this.mask = this.element.find('#mask');
    this.playerContainer = this.element.find('[data-embed-target]')
    this.playerContainer.find('img').hide()

    this.player = new Player(this.playerContainer.get(0), {
      id: this.getData('vimeo-video-id'),
      autoplay: false,
      fullscreen: true,
      responsive: true,
      dnt: 1,
    })

    const controller = new ScrollMagic.Controller();
    const tl = gsap.timeline();
    tl.to(this.mask, {
      duration: 1,
      scale: 2.5
    });

    const scene = new ScrollMagic.Scene({
      triggerElement: '.wrapper',
      triggerHook: 0.4,
      duration: '60%'
    });

    scene.setTween(tl);
    scene.addTo(controller);

    // Rimuovi il tween precedente se presente
    if (scene.tweenController) {
      scene.tweenController.kill();
    }

    this.mask.on('click', () => {
      this.onMaskClick()
    });
  }

  onMaskClick() {
    console.log('Mask click')

    this.mask.hide();

    this.player.play().then(() => {
      const iframe = this.element.find('iframe')

      if (iframe.requestFullScreen) {
        iframe.requestFullScreen();
      } else if (iframe.webkitRequestFullscreen) {
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) {
        iframe.msRequestFullscreen();
      }
    });

    // Aggiungi il gestore di eventi per l'uscita dalla modalità fullscreen
    document.addEventListener("fullscreenchange", function () {
      if (!document.fullscreenElement) {
        this.player.pause();
        this.mask.show();
      }
    });
  }
}
