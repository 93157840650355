import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton()
import $ from 'jquery';


$( document ).ready(function() {

  // seleziona tutti gli elementi con la classe .circle-link
  const circleLinks = gsap.utils.toArray('.circle-link');
  
  // crea un trigger per ogni elemento
  circleLinks.forEach(circleLink => {
    // crea un tween per l'animazione di parallasse
    const tween = gsap.to(circleLink, {
      yPercent: -40,
      duration: 1,
      ease: 'pease'
    });
  
    // crea un trigger per l'elemento
    const trigger = {
      trigger: circleLink,
      start: 'top bottom',
      end: 'bottom top',
      scrub: true
    };
  
    // collega il tween al trigger
    ScrollTrigger.create(trigger, tween);
  });
  
});