import $ from 'jquery';

export default class Component {
  constructor(element, context) {
    this.element = $(element);
    this.context = context;
  }

  getData(key, def = false) {
    return this.element.data(key) ? this.element.data(key) : def;
  }

  addContextFn(name, fn) {
    this.context[name] = fn;
  }
}
