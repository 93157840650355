import $ from 'jquery';
import { gsap } from 'gsap';
import Component from "../models/Component";

export default class MainMenu extends Component {
  constructor(element, context) {
    super(element, context);
    $(() => {
      this.init();
    });
  }

  init() {

    let isOpen = false; // inizialmente il menu non è aperto
    let header = $("header");

    // Se il body e scuro
    if($('body').hasClass('archive', 'dark')) {
      header.addClass("c-color");
    }
    
    this.element.on('click', (e) => {
      
      e.preventDefault();
      e.stopPropagation();
      
      if (!isOpen) { // se il menu non è aperto
        $('#dottedbg').css('z-index', '15');
        const tl = gsap.timeline();
        tl.to('#dottedbg', {
/*           x: $('#dottedbg').offset().left,
          y: $('#dottedbg').offset().top, */
          scale: Math.max(window.innerWidth, window.innerHeight),
          ease: "power3.in"
        });
        $('body').css('overflow', 'hidden');
        setTimeout(function(){
          $('#menu-items, .information, #close').fadeIn();
          $('header').addClass('open');
          $('#menu span').text("Chiudi");
          isOpen = true; // setta il flag a true per indicare che il menu è aperto
        },350);
      } else { // se il menu è aperto
        const tl = gsap.timeline();
        tl.to('#dottedbg', {
          x: 0,
          y: 0,
          scale: 1,
          ease: "power3.out"
        });
        $('body').css('overflow', 'hidden');
        setTimeout(function(){
          $('header').removeClass('open');
          $('#menu span').text("Menu");
          $('body').css('overflow', 'auto');
          $('#dottedbg').css('z-index', '9999');
          $('#close, #menu-items, .information').hide();          
          isOpen = false; // setta il flag a false per indicare che il menu è chiuso
        }, 250);
      }

      if($(header).hasClass('c-color')) {
        if(!$('body').hasClass('archive', 'dark')) {
          $(header).removeClass('c-color');
        }
      }
    });

    $(window).on("scroll", function() {
      var headerHeight = header.outerHeight();
      var scrollTop = $(this).scrollTop();
    
      if (!isOpen && scrollTop > headerHeight) {
        header.addClass("c-color");
        header.css('background', 'transparent');
      } 
      else {
        if($('body').hasClass('archive', 'dark')) {
          header.css('background', 'transparent');
          header.addClass("c-color");
        }
        else {
          header.removeClass("c-color");
          header.css('background', 'white');
        }
      }
    });
  }
}
