import $ from 'jquery';

let b = false,
  p = false;

export const $Win = $(window);
export const $Doc = $(document);

export const $Body = () => {
  if (b === false) {
    b = $('body');
  }
  return b;
};

export const $Page = () => {
  if (p === false) {
    p = $('html, body');
  }
  return p;
};
