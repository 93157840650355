import $ from 'jquery';


$( document ).ready(function() {

  var lazyVideos = document.querySelectorAll('.lazy-video');
  var observer = new IntersectionObserver(function(entries) {
    entries.forEach(function(entry) {
      if (entry.intersectionRatio >= 0.8) {
        var video = entry.target;
        video.play();
      }
    });
  }, { threshold: 0.8 });


  lazyVideos.forEach(function(video) {
    observer.observe(video);
  });

});
