import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function gsapSingleton() {
 return gsap;
}

export function scrollTriggerSingleton() {
  return ScrollTrigger;
}